var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stage-viewer" } },
    [
      _c("gc-stage-viewer-layout", { staticClass: "global-components" }, [
        _c(
          "div",
          { attrs: { slot: "accountHeader" }, slot: "accountHeader" },
          [
            _vm.showBackToOverviewButton && _vm.currentCompany?.name
              ? _c("gc-stage-account-header", {
                  staticClass: "global-components",
                  attrs: {
                    "button-label": "Back to Overview",
                    name: _vm.currentCompany.name,
                  },
                  on: {
                    selected: function ($event) {
                      return _vm.goToOverview()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "stageHeader" }, slot: "stageHeader" },
          [
            _vm.loaded && _vm.stage.title
              ? [
                  _c("gc-stage-header", {
                    staticClass: "global-components",
                    attrs: { heading: _vm.stage.title },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { attrs: { slot: "stepProgressBar" }, slot: "stepProgressBar" },
          [
            _vm.loaded && _vm.steps?.length > 1
              ? [
                  _c("gc-step-progress-bar", {
                    staticClass: "global-components",
                    attrs: {
                      steps: JSON.stringify(_vm.steps),
                      slide: JSON.stringify(_vm.slide),
                    },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          {
            ref: "slide",
            staticClass: "slide",
            attrs: { slot: "slideLayout" },
            slot: "slideLayout",
          },
          [
            _vm.loaded
              ? [
                  _c("gc-slide-header", {
                    staticClass: "global-components",
                    attrs: { heading: _vm.slide.heading },
                  }),
                  _c(
                    _vm.currentSlideComponent,
                    _vm._g(
                      _vm._b(
                        {
                          ref: "currentSlide",
                          tag: "component",
                          attrs: { slide: JSON.stringify(_vm.slide) },
                          on: { "slide-processing": _vm.handleSlideProcessing },
                        },
                        "component",
                        _vm.slideBindings,
                        false
                      ),
                      _vm.$listeners
                    )
                  ),
                  _vm.slide.slide_decisions?.length
                    ? _c("gc-slide-decision-buttons", {
                        staticClass: "mt-4 global-components",
                        attrs: {
                          "slide-decisions": JSON.stringify(
                            _vm.slide.slide_decisions
                          ),
                        },
                        on: { next: (event) => _vm.next(event.detail[0]) },
                      })
                    : _vm._e(),
                ]
              : _c("ct-centered-spinner", [
                  _vm._v("\n        Loading Slide...\n      "),
                ]),
          ],
          2
        ),
        _c(
          "div",
          {
            ref: "navigation",
            staticClass: "navigation-container",
            attrs: { slot: "navigationContainer" },
            slot: "navigationContainer",
          },
          [
            _vm.loaded
              ? [
                  _c("gc-slide-navigation-container", {
                    staticClass: "global-components",
                    attrs: {
                      "continue-button-label": _vm.continueButtonLabel,
                      "back-button-label": _vm.backButtonLabel,
                      "show-continue-button": JSON.stringify(
                        _vm.showContinueButton
                      ),
                      "show-back-button": JSON.stringify(_vm.showBackButton),
                      "disable-continue-button": JSON.stringify(
                        _vm.disableContinueButton
                      ),
                      loaded: _vm.loaded,
                      "slide-processing": JSON.stringify(_vm.slideProcessing),
                    },
                    on: {
                      next: function ($event) {
                        return _vm.next()
                      },
                      back: function ($event) {
                        return _vm.back()
                      },
                    },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }