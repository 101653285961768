<template>
  <div id="stage-viewer">
    <gc-stage-viewer-layout class="global-components">
      <div slot="accountHeader">
        <gc-stage-account-header
          v-if="showBackToOverviewButton && currentCompany?.name"
          class="global-components"
          button-label="Back to Overview"
          :name="currentCompany.name"
          @selected="goToOverview()"
        />
      </div>

      <div slot="stageHeader">
        <template v-if="loaded && stage.title">
          <gc-stage-header :heading="stage.title" class="global-components" />
        </template>
      </div>

      <div slot="stepProgressBar">
        <template v-if="loaded && steps?.length > 1">
          <gc-step-progress-bar :steps="JSON.stringify(steps)" :slide="JSON.stringify(slide)" class="global-components" />
        </template>
      </div>

      <div slot="slideLayout" ref="slide" class="slide">
        <template v-if="loaded">
          <gc-slide-header :heading="slide.heading" class="global-components" />

          <component
            :is="currentSlideComponent"
            ref="currentSlide"
            :slide="JSON.stringify(slide)"
            v-bind="slideBindings"
            v-on="$listeners"
            @slide-processing="handleSlideProcessing"
          />

          <gc-slide-decision-buttons
            v-if="slide.slide_decisions?.length"
            class="mt-4 global-components"
            :slide-decisions="JSON.stringify(slide.slide_decisions)"
            @next="(event) => next(event.detail[0])"
          />
        </template>
        <ct-centered-spinner v-else>
          Loading Slide...
        </ct-centered-spinner>
      </div>
      <div slot="navigationContainer" ref="navigation" class="navigation-container">
        <template v-if="loaded">
          <gc-slide-navigation-container
            class="global-components"
            :continue-button-label="continueButtonLabel"
            :back-button-label="backButtonLabel"
            :show-continue-button="JSON.stringify(showContinueButton)"
            :show-back-button="JSON.stringify(showBackButton)"
            :disable-continue-button="JSON.stringify(disableContinueButton)"
            :loaded="loaded"
            :slide-processing="JSON.stringify(slideProcessing)"
            @next="next()"
            @back="back()"
          />
        </template>
      </div>
    </gc-stage-viewer-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
export default {
  name: 'StageViewer',
  components: {
    CtCenteredSpinner:      () => import('@/components/shared/CtCenteredSpinner.vue'),
    SelectPhoneNumberSlide: () => import('@/components/StagelineV3/SlideTypes/ServiceConfiguration/SelectPhoneNumberSlide.vue'),
    SearchDomainSlide:      () => import('@/components/StagelineV3/SlideTypes/ServiceConfiguration/SearchDomainSlide.vue'),
    ShowPhoneNumberSlide:   () => import('@/components/StagelineV3/SlideTypes/ServiceInfo/ShowPhoneNumberSlide.vue'),
    ShowAddressSlide:       () => import('@/components/StagelineV3/SlideTypes/ServiceInfo/ShowAddressSlide.vue'),
    ShowDomainSlide:        () => import('@/components/StagelineV3/SlideTypes/ServiceInfo/ShowDomainSlide.vue'),
  },
  mixins: [makeToastMixin],

  data() {
    return {
      loaded: false,
      companyId: null,
      backButtonLabel: 'Previous',
      slideProcessing: false,
      showBackToOverviewButton: false,
    }
  },

  computed: {
    ...mapGetters('companies', [
      'currentCompany',
      'domesticJurisdiction',
    ]),
    ...mapGetters('stagelineV3', [
      'stage',
      'stages',
      'accountsStage',
      'accountsStages',
      'steps',
      'slide',
      'isFirstSlide',
      'isLastSlide',
    ]),
    currentSlideComponent() {
      const slideLayoutTypeMapping = {
        EndOfStageSlide: 'gc-end-of-stage-slide',
        RestingSlide: 'gc-resting-slide',
        SelectPhoneNumberSlide: 'SelectPhoneNumberSlide',
        SearchDomainSlide: 'SearchDomainSlide',
        ShowPhoneNumberSlide: 'ShowPhoneNumberSlide',
        ShowAddressSlide: 'ShowAddressSlide',
        ShowDomainSlide: 'ShowDomainSlide',
      }

      return slideLayoutTypeMapping[this.slide?.layout_type] || null
    },
    slideBindings() {
      return {
        company: this.currentCompany,
        steps: this.steps,
        slide: this.slide,
      }
    },
    continueButtonLabel() {
      return this.slide?.config?.continue_button?.text || (this.isLastSlide ? 'Finish' : 'Continue')
    },
    disableContinueButton() {
      return this.slide?.config?.continue_button?.disabled || false
    },
    showContinueButton() {
      return !this.slide?.config?.continue_button?.disabled
    },
    showBackButton() {
      return !this.isFirstSlide && !this.slide?.config?.back_button?.disabled
    },
  },

  async mounted() {
    this.companyId = this.$route.params.companyId
    if (this.companyId && this.companyId !== this.currentCompany?.id) {
      await this.setCurrentCompany({ id: this.companyId })
    }

    this.showBackToOverviewButton = true
    await this.loadAccountsStage()
  },

  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('stagelineV3', [
      'updateAccountsStage',
      'getAccountsStage',
      'getAccountsStages',
    ]),

    handleSlideProcessing(event) {
      this.slideProcessing = event
    },

    async transitionSlide(direction, entering = true) {
      const distance = direction === 'rtl' ? '25px' : '-25px'
      const slide = this.$refs['slide']
      if (slide) {
        slide.classList.add('slide-transition')
        slide.style.opacity = entering ? '0' : '1'
        slide.style.left = entering ? distance : '0px'
        await this.$nextTick()
        slide.style.opacity = entering ? '1' : '0'
        slide.style.left = entering ? '0px' : distance
        await new Promise(resolve => setTimeout(resolve, 300)) // wait for 300ms
        slide.classList.remove('slide-transition')
      }
    },

    async transitionEnter(direction) {
      await this.transitionSlide(direction)
    },

    async transitionLeave(direction) {
      await this.transitionSlide(direction, false)
    },

    async next(slide_decision_id = null) {
      const childComponent = this.$refs.currentSlide
      if (childComponent?.beforeContinue) {
        const shouldProceed = await childComponent.beforeContinue()
        if (!shouldProceed) return // Stop if child says not to proceed
      }

      await Promise.all([
        this.transitionLeave('rtl'),
        this.updateAndLoadStageForAction('next', slide_decision_id),
      ])
      await this.transitionEnter('ltr')
    },

    async back(slide_decision_id = null) {
      const childComponent = this.$refs.currentSlide
      if (childComponent?.beforeBack) {
        const shouldProceed = await childComponent.beforeBack()
        if (!shouldProceed) return
      }

      await Promise.all([
        this.transitionLeave('ltr'),
        this.updateAndLoadStageForAction('back', slide_decision_id),
      ])
      await this.transitionEnter('rtl')
    },

    async updateAndLoadStageForAction(action_type, slide_decision_id) {
      this.loaded = false

      // Need to store off the previous state since this gets updated in the next call.
      const isLastSlide = this.isLastSlide

      try {
        await this.updateAccountsStage({
          id: this.accountsStage.id,
          type: "accounts_stage",
          include: ['current_slide', 'current_slide.slide_decisions', 'current_slide.image'],
          data: {
            action_type,
            ...(slide_decision_id != null && { slide_decision_id }),
            company_id: this.companyId,
          },
        })

        if (isLastSlide && action_type === 'next') {
          await this.handleStageCompleted()
        }
      } catch (error) {
        await this.handleErrors(error)
      } finally {
        this.loaded = true
      }
    },

    async handleStageCompleted() {
      try {
        await this.updateAccountsStage({
          id: this.accountsStage.id,
          data: {
            action_type: 'complete',
            company_id: this.companyId,
          },
        })
      } catch (error) {
        await this.handleErrors(error)
      }

      try {
        await this.getAccountsStages({
          page: { number: 1, size: 1 },
          company_id: this.companyId,
          sort: ['-stages_category_priority', '-stage_priority'],
        })
        if (this.$route.query?.return_to) {
          await this.$router.push({ name: this.$route.query.return_to })
        } else if (this.accountsStages.length > 0) {
          await this.loadAndGoToNextStage(this.accountsStages[0].id)
        } else {
          await this.goToOverview()
        }
      } catch {
        this.errorToast("Error", "Something went wrong loading tasks")
        await this.goToOverview()
      }
    },

    async goToOverview() {
      await this.$router.push({ name: 'overview', params: {
          companyId: this.companyId,
          slideId: this.currentSlide?.id,
        },
      })
    },
    async loadAndGoToNextStage(accountsStageId) {
      await this.$router.push({ name: 'stage-viewer', params: {
          companyId: this.companyId,
          accountsStageId,
        },
      })

      await this.loadAccountsStage()
    },
    async loadAccountsStage() {
      try {
        this.loaded = false
        await this.updateAccountsStage({
          id: this.$route.params.accountsStageId,
          type: "accounts_stage",
          company_id: this.companyId,
          include: [
            'stage',
            'stage.steps',
            'current_slide',
            'current_slide.slide_decisions',
            'current_slide.image',
          ],
          data: {
            action_type: "view",
            company_id: this.companyId,
          },
        })
      } catch (error) {
        await this.handleErrors(error)
      } finally {
        this.loaded = true
      }
    },
    // TODO: Add more error handling
    async handleErrors(error) {
      if (error?.response?.status === 404) {
        await this.$router.push({ path: '/404' })
      } else {
        this.errorToast("Error", "Something went wrong")
      }
    },
  },
}
</script>

<style scoped lang="scss">
#stage-viewer {
  overflow: hidden;
  width: 100%;
  justify-items: center;

  .slide {
    position: relative;
  }

  .slide-transition {
    transition: opacity 0.3s, left 0.3s;
  }
}

:deep(hr) {
  border-top: 1px solid #e2e8f0 !important;
}
</style>
